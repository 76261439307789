import React from 'react';

// Own components
import SEO from '../components/seo';
import About from '../containers/About';
import Layout from '../components/Layout/en';

const AboutPage = () => (
    <Layout>
      <SEO title="Welcome" />
      <About />
    </Layout>
  );

export default AboutPage;
