// Short description max length 130 characters
export const timeLine = [
  {
    id: '2010',
    year: 2010,
    title: '2010Title',
    shortDescription: '2010shortDescription',
    description: '2010Description',
    image: '/images/about/timeline/2010.jpeg'
  },
  {
    id: '2013',
    year: 2013,
    title: '2013Title',
    shortDescription: '2013shortDescription',
    description: '2013Description',
    image: '/images/about/timeline/2013.jpeg'
  },
  {
    id: '2015',
    year: 2015,
    title: '2015Title',
    shortDescription: '2015shortDescription',
    description: '2015Description',
    image: '/images/about/timeline/2015.jpeg'
  },
  {
    id: '2016',
    year: 2016,
    title: '2016Title',
    shortDescription: '2016shortDescription',
    description: '2016Description',
    image: '/images/about/timeline/2016.jpeg'
  },
  {
    id: '2017',
    year: 2017,
    title: '2017Title',
    shortDescription: '2017shortDescription',
    description: '2017Description',
    image: '/images/about/timeline/2017.jpeg'
  },
  {
    id: '2019',
    year: 2019,
    title: '2019Title',
    shortDescription: '2019shortDescription',
    description: '2019Description',
    image: '/images/about/timeline/2019.jpeg'
  },
  {
    id: '2020',
    year: 2020,
    title: '2020Title',
    shortDescription: '2020shortDescription',
    description: '2020Description',
    image: '/images/about/timeline/2020.jpeg'
  },
];