import React, { useEffect } from 'react';
import { useIntl, FormattedMessage, FormattedHTMLMessage } from 'react-intl';

// Own components
import Icon from '../../components/Icon/icon';
import SectionCover from '../../components/SectionCover/sectionCover';

// Assets & Misc
import ukFlag from '../../images/logos/uk.png';
import { counters } from '../../constants/index';
import { timeLine } from '../../constants/about';
import spainFlag from '../../images/logos/spain.png';
import germanyFlag from '../../images/logos/germany.png';
import { animateCounters } from '../../utils/animations';

const About = () => {
  const { formatMessage } = useIntl();

  useEffect(() => {
    animateCounters();

    return () => {
      animateCounters();
    }
  }, []);

  return (
    <>
      <SectionCover title={formatMessage({ id: 'aboutUs' }, { prefix: '' })} src="/images/about/about-cover.jpeg" />
      {/* why choose us */}
      <section className="bestthing-section why-choose-section why-choose-section_01">
        <div className="container">
          <div className="row ">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 bestthing-text-column">
              <FormattedMessage id="whyChooseUs" tagName="h3" />
              <p className="fnt-17 text-justify">
                 <FormattedMessage id="whyChooseUsDescription"/>
              </p>
              <ul className="choose-list">
                <li>
                  <Icon icon="arrow-circle-o-right" />
                  <FormattedMessage id="technicalAdviceAndSupport" />
                </li>
                <li>
                  <Icon icon="arrow-circle-o-right" />
                  <FormattedMessage id="productAndApplicationDevelopment" />
                </li>
                <li>
                  <Icon icon="arrow-circle-o-right" />
                  <FormattedMessage id="productsServiceshighestQuality" />
                </li>
                <li>
                  <Icon icon="arrow-circle-o-right" />
                  <FormattedMessage id="experiencedHighlyTrainedStaff" />
                </li>
                <li>
                  <Icon icon="arrow-circle-o-right" />
                  <FormattedMessage id="competitivePrices" />
                </li>
              </ul>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
               <img
                  data-src="/images/about/porque-elegirnos.jpeg"
                  className="img-responsive lazyload about-us-img"
                  alt="choose"
               />
            </div>
          </div>
        </div>
      </section>
      {/* Company History */}
      <section className="history-section">
         <div className="container">
            <div className="row">
               <div className="col-md-12 text-center">
                  <FormattedMessage id="companyHistory" tagName="h3"/>
               </div>
               {timeLine.map((element) => (
                  <div key={element.id} className="col-md-3 col-sm-6 history-list text-center">
                     <span className="top-img">
                        <img data-src={element.image} className="img-responsive lazyload timeline-image" alt="humble" />
                     </span>
                     <div className="history-list-middle">
                        <div className="white-circle">
                           <div className="white-circle-border">
                              <div className="yellow-circle">{element.year}</div>
                           </div>
                        </div>
                        <span className="year-circle" />
                     </div>
                     <FormattedMessage id={element.title} tagName="h5" />
                     <details className="line-height26 history-detail">
                        <summary><FormattedMessage id={element.shortDescription} /></summary>
                        <FormattedHTMLMessage id={element.description} />
                     </details>
                  </div>
               ))}
            </div>
         </div>
      </section>
      {/* High Quality */}
      <section className="hight-level-section">
        <div className="container">
          <div className="row">
              <div className="col-md-12 text-center">
                <FormattedMessage id="identityTitle" tagName="h2" />
              </div>
              <div className="col-lg-6 col-md-6 col-xs-12 col-xs-12">
                <p className="fnt-17 text-justify">
                   <FormattedMessage id="identityDescription" />
                </p>
              </div>
              <div className="col-lg-6 col-md-6 col-xs-12 col-xs-12 text-center">
                <ul className="icon_size">
                    <li className="vision-icon"><i className="fa fa-eye" /> <FormattedMessage id="vision" /></li>
                    <li className="value-icon"><i className="fa fa-line-chart" /> <FormattedMessage id="values" /></li>
                    <li className="mission-icon"><i className="fa fa-rocket" /> <FormattedMessage id="mission" /></li>
                </ul>
              </div>
          </div>
        </div>
      </section>
      {/* Statistics */}
      <section className="static-section yellow-background">
         <div className="container">
            <div className="row">
               <div className="col-md-12 text-center">
                  <h2><FormattedMessage id="someImportantData" tagName="span" /></h2>
                  <p className="fnt-18 text-justify">
                     <FormattedMessage id="someImportantDataDescription" />
                  </p>
               </div>
               <ul>
                  {counters.map(({ id: counterId, value: counterValue, textId: counterText, unit }) => (
                    <li key={counterId}>
                      <h2>{counterValue}</h2>
                      <p style={{ paddingRight: '1rem', paddingLeft: '1rem' }}>
                      <FormattedMessage id={counterText} values={{ unit: unit ? `(${formatMessage({ id: unit })})` : '' }} />
                      </p>
                    </li>
                  ))}
               </ul>
            </div>
         </div>
      </section>
      {/* Team */}
      <section className="experiecnce-section">
         <div className="container">
            <div className="row">
               <div className="col-md-12 text-center">
                  <FormattedMessage id="ourTeam" tagName="h3" />
               </div>
               <div className="col-md-4 col-sm-4 experience-team ">
                  <div className="enitre_mouse">
                     <div className="shadow_effect effect-apollo"> <img src="/images/about/team/daniel.jpeg" className="img-responsive lazyload" alt="team" /> </div>
                  </div>
                  <h5>DANIEL GUTIERREZ</h5>
                  <div className="designation-container">
                     <span className="designation">
                        <FormattedMessage id="danielOccupation" />
                     </span>
                     <span className="designation designation-languages">
                        <Icon icon="language" />
                        <img data-src={spainFlag} alt="spain" className="lazyload" />
                        <img data-src={ukFlag} alt="uk" className="lazyload" />
                        <img data-src={germanyFlag} alt="germany" className="lazyload" />
                     </span>
                  </div>
                  <hr />
                  <p className="line-height26 fnt-16 mh-80">
                     <FormattedMessage id="danielDescription" />
                  </p>
               </div>
               <div className="col-md-4 col-sm-4 experience-team ">
                  <div className="enitre_mouse">
                     <div className="shadow_effect effect-apollo"> <img data-src="/images/about/team/adrian.jpeg" className="img-responsive lazyload" alt="team" /> </div>
                  </div>
                  <h5>ADRIAN GUTIERREZ</h5>
                  <div className="designation-container">
                     <span className="designation">
                        <FormattedMessage id="adrianOccupation" />
                     </span>
                     <span className="designation designation-languages">
                        <Icon icon="language" />
                        <img data-src={spainFlag} alt="spain" className="lazyload" />
                        <img data-src={ukFlag} alt="uk" className="lazyload" />
                     </span>
                  </div>
                  <hr />
                  <p className="line-height26 fnt-16 mh-80">
                     <FormattedMessage id="adrianDescription" />
                  </p>
               </div>
               <div className="col-md-4 col-sm-4 experience-team ">
                  <div className="enitre_mouse">
                     <div className="shadow_effect effect-apollo"> <img data-src="/images/about/team/veronica.jpeg" className="img-responsive lazyload" alt="team" /> </div>
                  </div>
                  <h5>VERONICA RIOS</h5>
                  <div className="designation-container">
                     <span className="designation">
                        <FormattedMessage id="veronicaOccupation" />
                     </span>
                     <span className="designation designation-languages">
                        <Icon icon="language" />
                        <img data-src={spainFlag} alt="spain" className="lazyload" />
                        <img data-src={ukFlag} alt="uk" className="lazyload" />
                     </span>
                  </div>
                  <hr />
                  <p className="line-height26 fnt-16 mh-80">
                     <FormattedMessage id="veronicaDescription" />
                  </p>
               </div>
               <div className="col-md-4 col-sm-4 experience-team ">
                  <div className="enitre_mouse">
                     <div className="shadow_effect effect-apollo"> <img data-src="/images/about/team/ernesto.jpeg" className="img-responsive lazyload" alt="team" /> </div>
                  </div>
                  <h5>ERNESTO BUITRAGO</h5>
                  <div className="designation-container">
                     <span className="designation">
                        <FormattedMessage id="ernestoOccupation" />
                     </span>
                     <span className="designation designation-languages">
                        <Icon icon="language" />
                        <img data-src={spainFlag} alt="spain" className="lazyload" />
                     </span>
                  </div>
                  <hr />
                  <p className="line-height26 fnt-16 mh-80">
                     <FormattedMessage id="ernestoDescription" />
                  </p>
               </div>
            </div>
         </div>
      </section>
    </>
  );
};

export default About;
